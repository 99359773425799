import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { lazy, Suspense } from "react";
import DemoContextProvider from "./context/demoContext";
import MainLayout from "./layout/MainLayout";
import CourseLayout from "./layout/CourseLayout";
import BookDemo from "./layout/BookDemo";
import Loader from "./components/Loader";

// Lazy loading components
const LandingPage = lazy(() => import("./pages/LandingPage"));
const Courses = lazy(() => import("./pages/courses/Courses"));
const Vocals = lazy(() => import("./pages/courses/Vocals"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const Login = lazy(() => import("./pages/auth/Login"));
const Otp = lazy(() => import("./pages/auth/Otp"));
const UserDashboard = lazy(() => import("./pages/UserDashboard"));
const AboutUs = lazy(() => import("./pages/about/AboutUs"));
const Collaborate = lazy(() => import("./pages/about/Collaborate"));
const IndividualBlog = lazy(() => import("./pages/about/IndividualBlog"));
const Corporate = lazy(() => import("./pages/program/Corporate"));
const Gigs = lazy(() => import("./pages/program/Gigs"));
const School = lazy(() => import("./pages/program/School"));
const Blog = lazy(() => import("./pages/about/Blog"));
const ChooseRole = lazy(() => import("./pages/bookDemo/ChooseRole"));
const ChooseTime = lazy(() => import("./pages/bookDemo/ChooseTime"));
const ContactDetails = lazy(() => import("./pages/bookDemo/ContactDetails"));
const Test = lazy(() => import("./pages/Test"));
const Main = lazy(() => import("./pages/hiring/Main"));
const ViewPosition = lazy(() => import("./pages/hiring/ViewPosition"));
const ViewDetails = lazy(() => import("./pages/hiring/ViewDetails"));
const ContactUs = lazy(() => import("./pages/support/ContactUs"));
const FAQs = lazy(() => import("./pages/support/FAQs"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route
        index
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <LandingPage />
          </Suspense>
        }
      />
      <Route
        path="login"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Login />
          </Suspense>
        }
      />
      <Route
        path="login-auth"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Otp />
          </Suspense>
        }
      />
      <Route
        path="user-dashboard"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <UserDashboard />
          </Suspense>
        }
      />
      <Route
        path="about/aboutUs"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <AboutUs />
          </Suspense>
        }
      />
      <Route
        path="about/collaborations"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Collaborate />
          </Suspense>
        }
      />
      <Route
        path="about/blogs"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Blog />
          </Suspense>
        }
      />
      <Route
        path="about/individualblog"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <IndividualBlog />
          </Suspense>
        }
      />
      <Route path="book-demo" element={<BookDemo />}>
        <Route
          path="choose-role"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ChooseRole />
            </Suspense>
          }
        />
        <Route
          path="choose-time"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ChooseTime />
            </Suspense>
          }
        />
        <Route
          path="contact-details"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ContactDetails />
            </Suspense>
          }
        />
      </Route>
      <Route element={<CourseLayout />}>
        <Route
          path="courses/instruments/:courseName"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Courses />
            </Suspense>
          }
        />
        <Route
          path="courses/vocals"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Vocals />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="hiring/:category"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Main />
          </Suspense>
        }
      />
      <Route
        path="hiring/position/:category"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <ViewPosition />
          </Suspense>
        }
      />
      <Route
        path="hiring/details/:category/:detailCategory"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <ViewDetails />
          </Suspense>
        }
      />
      <Route
        path="support/contact"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path="support/faqs"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <FAQs />
          </Suspense>
        }
      />
      <Route
        path="programs/school"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <School />
          </Suspense>
        }
      />
      <Route
        path="programs/corporate/:programType"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Corporate />
          </Suspense>
        }
      />
      <Route
        path="programs/live-gigs"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Gigs />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <PageNotFound />
          </Suspense>
        }
      />
      <Route
        path="test"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Test />
          </Suspense>
        }
      />
    </Route>
  )
);

function App() {
  return (
    <DemoContextProvider>
      <RouterProvider router={router} />
    </DemoContextProvider>
  );
}

export default App;
